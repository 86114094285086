import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Main from '../components/layout'
import Head from '../components/head'
import {
  Heading1,
  StyledLink,
  PageSubtitle,
  Heading3
} from '../components/typography'
import TravelSVG from '../../assets/travel.svg'
import MapSVG from '../../assets/world-with-states.svg'

const WorldMap = styled(MapSVG)`
  pointer-events: none;
  transform: scale(1.5);
  position: fixed;
  top: 70%;
  right: 50%;
  transform: translate(50%, -50%) scale(1.5);
  opacity: 0.7;

  path {
    fill: var(--orange);
    fill-opacity: 0.15;
    stroke: var(--cream-light);
    &.visited {
      fill: var(--green);
      fill-opacity: 0.5;
    }
  }

  #Canada,
  #China,
  #Brazil,
  #India {
    fill-opacity: 0.3;
  }

  @media (max-width: 60em) {
    opacity: 0.4;
  }
`

const TravelSection = styled.section`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 2em;
`

const TravelHeader = styled.header`
  position: relative;
  margin: 0 var(--bleed-side);

  h1,
  p {
    position: relative;
  }
`

const TravelImage = styled(TravelSVG)`
  transform: scale(0.5) rotate(-8deg);
  position: absolute;
  left: 10em;
  bottom: 1em;
  z-index: 1;
  transition: all 0.3s ease-in;

  .marker-start,
  .marker {
    fill: var(--pink);
  }

  .marker-fill {
    fill: var(--white);
    fill-opacity: 0.9;
  }

  .line {
    fill: var(--green);
  }

  @media (max-width: 80em) {
    transform: scale(0.5) rotate(-8deg);
    left: 8em;
  }

  @media (max-width: 60em) {
    transform: scale(0.83) rotate(-8deg);
    position: initial;
    margin: -3em -1em;
    opacity: 0.8;
  }

  @media (max-width: 40em) {
    max-width: 100%;
    margin: -3em auto;
  }
`

const TravelContent = styled.article`
  background: var(--cream-light);
  display: flex;
  flex-wrap: wrap;
  margin: 0 var(--bleed-side);
`

const TravelSubheader = styled(Heading3)`
  display: block;
  width: 100%;
  margin-top: 2em;
  position: relative;
  z-index: 1;
`

const TravelCard = styled.div`
  margin: 0 1em 1em 0;

  ${StyledLink} {
    padding: 0.5em;
    background: var(--cream);
    box-shadow: var(--blue-light) 0.313em 0.313em 0 -0.125em;
    transition: all 0.3s ease-in;

    &::before,
    &::after {
      display: none;
    }

    &:hover,
    &:focus-visible {
      box-shadow: var(--green) 0.313em 0.313em 0 -0.125em,
        var(--cream) 0.313em 0.313em,
        var(--blue-light) 0.625em 0.625em 0 -0.125em,
        var(--cream) 0.625em 0.625em,
        var(--green-light) 0.938em 0.938em 0 -0.125em,
        var(--cream) 0.938em 0.938em;
      background: var(--cream-dark);
    }

    &:focus-visible {
      outline: 0.125em solid var(--green-dark);
      outline-offset: 0;
    }
  }
`

function TravelIndex({ data }) {
  const { edges: posts } = data.allMdx

  const travelPages = posts.filter((page) =>
    page.node.fields.slug.includes('travel')
  )

  const sortedPages = travelPages.sort((a, b) =>
    a.node.frontmatter.title > b.node.frontmatter.title ? 1 : -1
  )

  return (
    <Main>
      <Head title="Travel" />
      <WorldMap />

      <TravelSection>
        <TravelHeader>
          <TravelImage />
          <Heading1>My Journeys</Heading1>
          <PageSubtitle>
            I explore the world by land, air, and sea. Anything that
            communicates is fair game&mdash; apps, drawings, gestures, laughs.
            The best souvenir is to learning to cook what I miss!
          </PageSubtitle>
        </TravelHeader>

        <TravelContent>
          {/* <TravelSubheader>International</TravelSubheader> */}
          {sortedPages
            .filter(({ node: post }) => post.frontmatter.category !== 'USA')
            .map(({ node: post }) => (
              <TravelCard key={post.id}>
                <StyledLink to={`/${post.fields.slug}`}>
                  {post.frontmatter.title}
                </StyledLink>
              </TravelCard>
            ))}

          <TravelSubheader>States</TravelSubheader>
          {sortedPages
            .filter(({ node: post }) => post.frontmatter.category === 'USA')
            .map(({ node: post }) => (
              <TravelCard key={post.id}>
                <StyledLink to={`/${post.fields.slug}`}>
                  {post.frontmatter.title}
                </StyledLink>
              </TravelCard>
            ))}
        </TravelContent>
      </TravelSection>
    </Main>
  )
}

export const pageQuery = graphql`
  query TravelIndex {
    allMdx {
      edges {
        node {
          id
          frontmatter {
            title
            category
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default TravelIndex
